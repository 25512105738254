import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from "../helpers";
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const reportTablesServices = {
    getTable4Data,
    getTable1Data,
    getTable5Data,
    getTable3Data,
    getTable2Data,
    submitTable,
    unSubmitTable,
    getTable2DataUpdate,
    updateTable4,
    updateTable3,
    createTable4,
    createTable3,
    createTable2,
    validationStatus,
    getTable1Errors,
    getIssues,
    downloadReportTable,
    downloadTable3Report,
    downloadTable4Report,
    checkIfTable4Exists,
    createTable5,
    updateTable5,
    getTable5Status
}

/**
 * Get Table 1 Data
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 * @param {*} projectData 
 */
function getTable1Data(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, projectData = {}, query = { isDelete: false} ) {
    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete,
        projectData: projectData,
        query: query
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/table1/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}


/**
 * Get Table 1 Data
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 * @param {*} projectData 
 */
function getTable5Data(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, projectData = {}) {
    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete,
        projectData: projectData
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/table5/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}


/**
 * A mehtod to get table 4 data
 *
 * @param {*} id report year table _id value
 */
function getTable4Data(id, type="projects") {
    if (id) {
        const requestOptions = {
            method: 'GET',
            headers: { ...authHeader() }
        };

        return fetch(`${globalVariable.apiURL}/report-tables/${id}/table4?timeZone=${momentTZ.tz.guess(true)}&type=${type}`, requestOptions).then(handleResponse);
    }
}

/**
 * Method to get the table 2 datas
 *
 * @param {*} id report year table _id value
 */
function getTable2Data(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-tables/${id}/table2`, requestOptions).then(handleResponse);
}

/**
 * Method to update table 2 data
 *
 * @param {*} id contains table 2 _id
 * @param {*} formData contains reportYear_id - must
 */
function getTable2DataUpdate(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };
    return fetch(`${globalVariable.apiURL}/report-tables/${id}/table2`, requestOptions).then(handleResponse);
}

/**
 * A mehtod to get table 3 data
 *
 * @param {*} id report year table _id value
 */
function getTable3Data(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }

    };
    return fetch(`${globalVariable.apiURL}/report-tables/${id}/table3`, requestOptions).then(handleResponse);
}

/**
 * Service call update table 1 activity submit
 *
 * @param {*} id contains reportyear id
 * @param {*} formData contains form data
 * @returns
 */
function submitTable(id, formData, tableIndex) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/${id}/${tableIndex}/submit`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call update table 1 activity unsubmit
 *
 * @param {*} id contains reportyear id
 * @param {*} formData contains form data
 * @returns
 */
function unSubmitTable(id, formData, tableIndex) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/${id}/${tableIndex}/un-submit`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call update table 4 record
 *
 * @param {*} id contains table 4 _id
 * @param {*} formData contains form data
 * @returns
 */
function updateTable4(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/${id}/table4`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call update table 5 record
 *
 * @param {*} id contains table 5 _id
 * @param {*} formData contains form data
 * @returns
 */

function updateTable5(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };  
     return fetch(`${globalVariable.apiURL}/report-tables/${id}/table5`, requestOptions).then(handleResponse);
    }
}
/**
 * Service call update table 3 record
 *
 * @param {*} id contains table 3 _id
 * @param {*} formData contains form data
 * @returns
 */
function updateTable3(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/${id}/table3`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call create table 5 record
 *
 * @param {*} formData contains table 5 form data { reportYear_id is must }
 * @returns
 */

function createTable5(formData) {
    if (formData.reportYear_id) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/table5`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call create table 4 record
 *
 * @param {*} formData contains table 4 form data { reportYear_id is must }
 * @returns
 */
function createTable4(formData) {
    if (formData.reportYear_id) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/table4`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call create table 3 record
 *
 * @param {*} formData contains table 3 form data { reportYear_id is must }
 * @returns
 */
function createTable3(formData) {
    if (formData.reportYear_id) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-tables/table3`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call create table 2 record
 *
 * @param {*} formData contains table 2 form data { reportYear_id is must }
 * @returns
 */
function createTable2(formData) {
    if (formData) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        return fetch(`${globalVariable.apiURL}/report-tables/table2`, requestOptions).then(handleResponse);
    }
}

/**
 * 
 * @param {*} id 
 */
function validationStatus(projectId, reportYear) {
    if (projectId && reportYear) {
        const requestOptions = {
            method: 'GET',
            headers: { ...authHeader() }
        };

        return fetch(`${globalVariable.apiURL}/report-tables/validation/${projectId}/${reportYear}`, requestOptions).then(handleResponse);
    }
}

/**
 * Get table 1 errors
 * @param {*} reportYear_id 
 */
function getTable1Errors(reportYear_id) {
    if (reportYear_id) {
        const requestOptions = {
            method: 'GET',
            headers: { ...authHeader() }
        };

        return fetch(`${globalVariable.apiURL}/table1/errors/${reportYear_id}`, requestOptions).then(handleResponse);
    }
}

/**
 * Get validation issues
 * @param {*} reportYear_id 
 */
function getIssues(project_id = 0, site_id = 0, reportYear_id = 0, reportTable = '') {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/validations/${project_id}/${site_id}/${reportYear_id}/${reportTable}`, requestOptions).then(handleResponse);
}

/**
 * Download Report table
 * 
 * @param {*} data - table data
 */
function downloadReportTable(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.ms_apiURL}/report-tables/download/pdf`, requestOptions).then(handleDownloadResponse);
}

/**
 * Table 3 Report
 * @param {*} reportYear_id 
 * @param {*} year 
 */
function downloadTable3Report(project, site,year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        
    };
    return fetch(`${globalVariable.ms_apiURL}/table3Reports/${project}/${site}/${year}`, requestOptions).then(handleDownloadResponse);
}

/**
 * Table 4 Report
 * @param {*} reportYear_id 
 * @param {*} year 
 */
function downloadTable4Report(project, site,year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        
    };
    return fetch(`${globalVariable.ms_apiURL}/table4Reports/${project}/${site}/${year}`, requestOptions).then(handleDownloadResponse);
}

function checkIfTable4Exists(reportYear_id){
    const requestOptions = {
        method: 'GET',
        headers : authHeader()
    }

    return fetch(`${globalVariable.apiURL}/check-table4-record/${reportYear_id}`, requestOptions).then(handleResponse);
}

function getTable5Status(projectData = {}) {
    let data = {
        projectData: projectData
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/table5/status?timeZone=${timeZone}`, requestOptions).then(handleResponse);
}