import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helpers
import { getUserRole, getUserOrgId, globalVariable, history, getSubmissionLevel, ReportLevelsConstants, calcDate, getDateDifference, filterSearchFields,convertDateWithTimeZone } from '../../../helpers';

// Import Components
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import MaterialTableComponent from '../../../components/Table/MaterialTable';
import FieldFilter from "../../../components/FieldFilter/FieldFilter";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";

// Import Redux Actions and Helper Variable
import { reportTablesActions } from '../../../actions/reportTables';
import { confActions } from '../../../actions/configuration';
import { alertActions, exploreActions, projectsActions, printActions, validationActions } from "../../../actions";
import Table5Edit from './Table5Sections/Table5Edit';
import TextArea from '../../../components/TextArea/TextArea';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";

let sFields = [];
let sValues = [];
class BoldTextMaxDaysVacant extends Component {
    render() {
        const { data: { totalDaysVacant,isNextMoveInPresent } } = this.props;
        let style = {};
        let ftStyle = { paddingLeft: "8px", position: 'relative' };
        if (!isNextMoveInPresent) {
            style = { position: 'absolute', display: 'block', top: 0, left: 0, zIndex: 0, width: '80%', height: '100%' };
            ftStyle = { fontWeight: 'bold', ...ftStyle };
        }

        return (
            <span style={{ display: 'block', position: 'relative' }}>
                <span style={style} />
                <span style={ftStyle}>{totalDaysVacant}</span>
            </span>
        );
    }
}

export const componentMap = {
    "totalDaysVacant": BoldTextMaxDaysVacant,
}
class Table5 extends Component {
     /**
     * Constructor
     * @param {*} props 
     */
     constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            type: null,
            comment: '',
            unsubmitComment: ''
        }
        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }

     /**
     * Handle Component Will Mount 
     */
    UNSAFE_componentWillMount = async ()=> {
        const { table5, projectData, location: { pathname } } = this.props;
        const { page } = table5;
        if (page.skip) {
            await this.props.getTable5Data(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, false, projectData);
        } else {
            await this.props.getTable5Data(1, globalVariable.subPagetableRowSize, 'unit.common_id', 'asc', '', '', false, projectData);
        }

        setTimeout(() => {
            this.props.getFieldFilter('table5',projectData.reportYear);
        }, 100);
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentDidMount() {
        
    }
    /**
     * Component Will Receive Props
     * @param {*} newProps 
     */
    UNSAFE_componentWillReceiveProps(newProps) {

    }
    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }

    /**
    * Handle Select the Column Filter Button Action
    * @memberof Users
    */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter("table5", this.props.table5.page.fieldFilters.visibleColumns);
    };

    handleNavigateAction = (id, field, item) => {
        const { pageAccess, match } = this.props;
        switch (field) {
            case 'unit.common_id': 
                history.push({
                    pathname: `/vacancyReport/${id}`, 
                    state: {
                        page: 'table5Edit',
                        pageData: {
                            data : item
                        }
                    }
                });
                break;
            default:
                break;
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    /** 
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("table5", this.props.table5.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     *A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("table5", updatedColumns);
        } else {
            this.props.changeFieldFilter("table5", [...this.props.visibleColumns, key]);
        }
    }

    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess, table5 } = this.props;
        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                // return  table5?.requiredNotSaved ? 'Table 5 Datas need to be saved before submitting' :
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div style={{ paddinBottom: "12px" }}>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                multiline={true}
                            // disabled={isReadOly}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }

     /**
     * Handle Field Filter On Save Event
     */
     handleFieldFilterOnSave = () => {
        const { table5, projectData, location: { pathname } } = this.props;
        const { page } = table5;
        this.props.updateFieldFilter("table5", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { table5, projectData, location: { pathname } } = this.props;
            this.props.getTable5Data(table5.page.skip, table5.page.limit, table5.page.orderBy, table5.page.order, page.sFields, page.sValues, false, projectData);
        });
    }


    handleDownloadActionClick = () => {
        const { projectData, siteData, table5 } = this.props;
        console.log("== PROJECT DATA ==", projectData, table5, this.props)
        const fieldFilters = table5.page ? table5.page?.fieldFilters : {};
        this.props.downloadTable5Report(projectData?.project_id,table5?.siteId,projectData?.reportYear,projectData?.reportId,fieldFilters?.headerCols)
    }   

    handleClearFilterActionClick = () => {
        sFields = [];
        sValues = [];
        const { table5, projectData, location: { pathname } } = this.props;
        this.props.getTable5Data(table5.page.skip, table5.page.limit, table5.page.orderBy, table5.page.order, sFields, sValues, false, projectData);
    }

    handleSaveActionClick = (type) => {
        let {table5} = this.props;
        if (type === 'SUBMIT') {
            if (table5?.requiredNotSaved) {
                this.props.showErrorAlert('Mandatory fields must be entered before Report Table 5 is submitted to the next level.');
                return;
            }
        }
        this.setState({type})
    }

    handleSubmit = () => {
       this._saveForm();
    }

    onChangePageTable = (skip,limit) => {
        const { table5, projectData, location: { pathname } } = this.props;
        this.props.getTable5Data(skip, limit, table5.page.orderBy, table5.page.order, sFields, sValues, false, projectData);
    }

    onSortRequest = (orderBy, order) => {
        const { table5, projectData, location: { pathname } } = this.props;
        this.props.getTable5Data(table5.page.skip, table5.page.limit, orderBy, order, sFields, sValues, false, projectData);
    }

    onPrint = () => {
        const { table5, projectData } = this.props;
        this.props.print(1, globalVariable.subPagetableRowSize, table5.page.orderBy, table5.page.order, sFields, sValues, false, projectData);
    };

    onInputChangeRequest = (searchField, searchKey) => {
        const { table5, projectData, location: { pathname } } = this.props;
        const { page } = table5;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.getTable5Data(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, projectData);
    }

    _saveForm = () => {
        const { type } = this.state;
        const { projectData } = this.props;

        if (projectData.reportId) {
            if (type === 'SUBMIT') {
                this.props.submitReport(projectData.reportId, {
                    comment: this.state.comment,
                    table1Comments: this.state.comment
                });
                this.setState({
                    type: null
                });
            } else {
                if (this.state.unsubmitComment !== '') {
                    this.props.unSubmitReport(projectData.reportId, {
                        comment: this.state.unsubmitComment,
                        table1Comments: this.state.comment
                    });
                    this.setState({
                        type: null
                    });
                }
            }
        }
    }

    render() {

        let { pageAccess, table5, columnsMap, visibleColumns, defaultCols, location: { pathname } } = this.props;
        let frozenCols = defaultCols.filter((x) => !['lastModifiedDate', 'lastModifiedBy'].includes(x));
        const { fieldFilter} = this.state;
        const { userRole, userOrgId } = this;
        const fieldFilters = table5.page ? table5.page.fieldFilters : {};
        const isReadOly = pageAccess.indexOf('SAVE') === -1; 
        const stickyHeaders = fieldFilters.headerCols.slice(0, 5);
        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';

        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any Funder for this report year.'}</h4>;
        }

        // Need to work on page Access
        return (
            <ContentWrapper
                ref={el => (this.container = el)}
                classes={{}}   
                pageAccess={[
                    'DOWNLOAD', ...pageAccess
                  ]}   
                isSubPage={true}   
                ischildPage={true}
                title={levelInfo}   
                titleCss={'bolderText'}
                pageName="reports"
                validationContent={[]}   
                needPadding={false}   
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);   
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleSubmitActionClick={() => this.handleSaveActionClick('SUBMIT')}
                handleUnSubmitActionClick={() => this.handleSaveActionClick('UNSUBMIT')}
                handleDownloadActionClick={this.handleDownloadActionClick}
                handlePrintActionClick={this.onPrint}
                titleBg={'white'}
                >
                    <MaterialTableComponent
                        container={this.container}  
                        page={table5.page.skip}  
                        rowsPerPage={table5.page.limit}  
                        count={table5.page.count}  
                        stickyHeaders={stickyHeaders}   
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        order={table5.page.order}
                        orderBy={table5.page.orderBy}
                        isLoading={table5.isLoading}
                        fieldComponentOnChange={() => {}}   
                        actions={[]}   
                        actionClassName={'actions-end'}
                        links={['unit.common_id']}    
                        boldText={['']}   
                        data={table5.data}   
                        highlightField={['unit.common_id']}   
                        onChangePageTable={this.onChangePageTable}   
                        onSortRequest={this.onSortRequest}   
                        onInputChangeRequest={this.onInputChangeRequest}   
                        handleTableDeleteAction={() => {}}   
                        resetSearchContent={false}   
                        paginationVisible={true}
                        sFields={filterSearchFields(sFields)}   
                        sValues={sValues}   
                        handleNavigateAction={this.handleNavigateAction}   
                        userOrgId={userOrgId}   
                        userRole={userRole}   
                        noDataErrorMessage={"No Records Found"}
                        currentPage={'table5'}   
                         componentMap={componentMap} 
                        showLoading={false}
                        deleteTooltip={'Delete Household'}
                        getDeleteTitle={() => (
                            <div>
                                Are you sure you want to delete the occupant <b>Dummy Name</b> in
                                <b> Unit: Dummy Unit</b>?
                            </div>
                        )}  
                        />
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                        <FieldFilter
                            open={fieldFilter}
                            handleOnClose={this.handleFieldFilterOnClose}
                            handleOnChange={this.handleFieldFilterOnChange}
                            handleOnSave={this.handleFieldFilterOnSave}
                            columnsMap={columnsMap}
                            visibleColumns={visibleColumns}
                            disabledCols={frozenCols}
                        />
                        <AlertDialog
                            open={this.state.type !== null}
                            title={'Warning:'}
                            onSave={() => this.refs.form.submit()}
                            onClose={() => {
                                this.setState({ type: null })
                            }}
                            saveText={'Submit'}
                        >
                        {this.popAlert(this.state.type)}
                    </AlertDialog>
                    </ValidatorForm>
                   
                </ContentWrapper>
        )
    }
}

/**
 * Set Props Types
 */
Table5.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    pageAccess: PropTypes.array
};
/**
 * Default Props
 */
Table5.defaultProps = {
    needPadding: true,
    isSubPage: false,
    title: '',
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['table5'] || {};
    return {
        pageAccess: state.pageAccess['table5'] || [],
        table5: state.reportTables.table5,
        columnsMap: columnsData.columnsMap || {},
        defaultCols: columnsData.defaultCols || [],
        visibleColumns: columnsData.visibleColumns || [],
        siteData: state.reportTables.table1.data[0]
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable5Data: (skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData) => dispatch(reportTablesActions.getTable5Data(skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData)),
        getFieldFilter: (table,year) => dispatch(confActions.getFieldFilter(table,year)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 5)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 5)),
        downloadTable5Report:  (project, site, year, reportYearId, fieldFilters) => dispatch(exploreActions.downloadTable5Report(project, site, year, reportYearId, fieldFilters, 'WBARS_Table5Export.xlsx')),
        print: (skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData) => dispatch(printActions.printTable5Data(skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
    }
};
/** Export Components */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Table5));