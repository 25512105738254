import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { connect } from 'react-redux';

// Materila UI
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Import Style
import sideMenuStyle from '../../assets/jss/components/sideMenu';

// Import Component
import ToolTip from '../../components/ToolTip/ToolTip';

// Import Helpers
import { getUserRole, history, constants, getUserOrgId, getUserOrgAccess,getMultifamilyAccess, getHomeownershipAccess } from '../../helpers';

//SVG Icons
import { ArchiveIcon, BuildingsIcon, ComplianceIcon, ConfigurationsIcon, DashboardIcon, DashboardIconHO, ExploreDataIcon, HelpIcon, LogsIcon, MailTemplateIcon, MenuIcon, OccupantsIcon, OrganizationIcon, ProjectsIcon, SettingsIcon, SitesIcon, UnitsIcon, UsersIcon, ContractActivity, InspectionIcon} from "../SvgIcons/SvgIcons";

/**
 *Side menu
 *
 * @class SideMenu
 * @extends {React.Component}
 */
class SideMenu extends React.Component {
    /**
     * Init Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        //state declaration
        this.state = {
            open: false
        };
        this.Menus = [];
    }
    /**
     *Component Will Receive props
     *
     * @memberof SideMenu
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { maintenance } = nextProps;
        console.log('getUserRole()!!!!!!!!!!!!!',getUserRole())
        const menuList = [
            (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) &&
            {
                pathname: '/dashboard',
                state: { page: 'dashboard', pageData: { naviagteToDashboardReports: false } },
                title: "Dashboard",
                name: "dashboard",
                icon: 'dashboard'
            },
            (getUserOrgAccess() === 2 || getUserOrgAccess() === 3) &&
            {
                pathname: '/dashboardho',
                state: { page: 'dashboardho' },
                title: "DashboardHO",
                name: "dashboardho",
                icon: 'dashboardho'
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                || constants.funderWithoutOA === getUserRole() || constants.funderWithoutOAandBoth === getUserRole()
                || constants.readOnlyFunderWithOA === getUserRole() || constants.readOnlyFunderWithOAandBoth === getUserRole()
                || constants.readOnlyFunderWithoutOA === getUserRole() || constants.readOnlyFunderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOA === getUserRole() || constants.nonFunderWithOAandBoth === getUserRole()
                || constants.nonFunderWithoutOA === getUserRole() || constants.nonFunderWithoutOAandBoth === getUserRole()) && (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) &&
            {
                pathname: '/projects',
                state: { page: 'projectsList' },
                title: "Projects",
                name: "projects",
                icon: "projects",
                table1: "table1-import-log",
                occupant: 'occupants'
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                || constants.funderWithoutOA === getUserRole() || constants.funderWithoutOAandBoth === getUserRole()
                || constants.readOnlyFunderWithOA === getUserRole() || constants.readOnlyFunderWithOAandBoth === getUserRole()
                || constants.readOnlyFunderWithoutOA === getUserRole() || constants.readOnlyFunderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOA === getUserRole() || constants.nonFunderWithOAandBoth === getUserRole()
                || constants.nonFunderWithoutOA === getUserRole() || constants.nonFunderWithoutOAandBoth === getUserRole()) && (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) &&
            {
                pathname: '/sites',
                state: { page: 'sitesList' },
                title: "Sites",
                name: "sites",
                icon: "sites"
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                || constants.funderWithoutOA === getUserRole() || constants.funderWithoutOAandBoth === getUserRole()
                || constants.readOnlyFunderWithOA === getUserRole() || constants.readOnlyFunderWithOAandBoth === getUserRole()
                || constants.readOnlyFunderWithoutOA === getUserRole() || constants.readOnlyFunderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOA === getUserRole() || constants.nonFunderWithOAandBoth === getUserRole()
                || constants.nonFunderWithoutOA === getUserRole() || constants.nonFunderWithoutOAandBoth === getUserRole()) && (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) &&
            {
                pathname: '/buildings',
                state: { page: 'buildingsList', main: true },
                title: "Buildings",
                name: "buildings",
                icon: "buildings",
                bulkupload: 'bulk-upload-building'
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                || constants.funderWithoutOA === getUserRole() || constants.funderWithoutOAandBoth === getUserRole()
                || constants.readOnlyFunderWithOA === getUserRole() || constants.readOnlyFunderWithOAandBoth === getUserRole()
                || constants.readOnlyFunderWithoutOA === getUserRole() || constants.readOnlyFunderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOA === getUserRole() || constants.nonFunderWithOAandBoth === getUserRole()
                || constants.nonFunderWithoutOA === getUserRole() || constants.nonFunderWithoutOAandBoth === getUserRole()) && (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) &&
            {
                pathname: '/units',
                state: { page: 'unitsList', main: true },
                title: "Units",
                name: "units",
                icon: "units",
                bulkupload: 'bulk-upload'
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                || constants.funderWithoutOA === getUserRole() || constants.funderWithoutOAandBoth === getUserRole()
                || constants.readOnlyFunderWithOA === getUserRole() || constants.readOnlyFunderWithOAandBoth === getUserRole()
                || constants.readOnlyFunderWithoutOA === getUserRole() || constants.readOnlyFunderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOA === getUserRole() || (constants.nonFunderWithOAandBoth === getUserRole() && !getHomeownershipAccess() && getMultifamilyAccess())
                || (constants.nonFunderWithOAandBoth === getUserRole() && getHomeownershipAccess() && getMultifamilyAccess())
                || constants.nonFunderWithoutOA === getUserRole() || (constants.nonFunderWithoutOAandBoth === getUserRole() && !getHomeownershipAccess() && getMultifamilyAccess())
                || (constants.nonFunderWithoutOAandBoth === getUserRole() && getHomeownershipAccess() && getMultifamilyAccess())
                ) &&
            {
                pathname: '/occupants',
                state: { page: 'occupantsList' },
                title: "Occupants",
                name: "occupants",
                icon: "occupants",
                occupan: true
            },
            {
                pathname: '/organizations',
                state: { page: 'organizationsList' },
                title: "Organizations",
                name: "organizations",
                icon: "organizations"
            },
            {
                pathname: '/users',
                state: { page: 'usersList' },
                title: "Users",
                name: "users",
                icon: "users"
            },

            // getUserRole ==
            ((constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()) || ((constants.funderWithOA === getUserRole() || (constants.funderWithOAandBoth === getUserRole() && getUserOrgId()===2 && getMultifamilyAccess() && !getHomeownershipAccess())
            || (constants.funderWithOAandBoth === getUserRole() && getUserOrgId()===2 && getMultifamilyAccess() && getHomeownershipAccess())
            )))
                && {
                    pathname: `/compliance/setting`,
                    state: {
                        page: 'compliancesetting',
                        pageData: {
                        }
                    },
                    title: "Compliance",
                    name: "compliance",
                    icon: "compliance"
                },
            ((constants.nonFunderWithOAandBoth))
                && {
                    pathname: '/compliance',
                    state: { page: 'compliance' },
                    title: "Compliance",
                    name: "compliance",
                    icon: "compliance"
                },

            (
                (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole() || constants.funderWithOA === getUserRole() || (constants.funderWithOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess()) || (constants.funderWithOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess()))
            )&&
            {
                pathname: '/inspection',
                state: { page: 'Inspection' },
                title: "Inspection",
                name: "inspection",
                icon: "inspectionIcon"
            },
            // ((constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole() || constants.funderWithOA === getUserRole() || constants.funderWithOAandBoth === getUserRole() || constants.funderWithoutOA === getUserRole() || (constants.funderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess()) || (constants.funderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess())))&&
            (getMultifamilyAccess() || getHomeownershipAccess())&&
            {
                pathname: '/explore',
                state: { page: 'explore' },
                title: "Explore Data",
                name: "explore",
                icon: "explore"
            },
            (constants.superFunderWithHO === getUserRole() || constants.readOnlyFunderWithoutOA === getUserRole() || constants.readOnlyFunderWithOA === getUserRole()
                || constants.funderWithOAandHO === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                || constants.funderWithoutOAandHO === getUserRole() || constants.funderWithoutOAandBoth === getUserRole()
                || constants.readOnlyFunderWithOAandHO === getUserRole() || constants.readOnlyFunderWithOAandBoth === getUserRole()
                || constants.readOnlyFunderWithoutOAandHO === getUserRole() || constants.readOnlyFunderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOAandHO === getUserRole() || constants.nonFunderWithOAandBoth === getUserRole()
                || constants.nonFunderWithoutOAandHO === getUserRole() || constants.nonFunderWithoutOAandBoth === getUserRole()) && (getUserOrgAccess() !== 1) &&
            {
                        pathname: '/loanProjects',
                        state: { page: 'loanProjects' },
                        title: "Homeownership Projects",
                        name: "loanProjects",
                        icon: "loanProjects"
            },
            ((constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole() || constants.funderWithOA === getUserRole() || (constants.funderWithOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess()) || (constants.funderWithOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess()) || constants.funderWithoutOA === getUserRole() || (constants.funderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess()) || (constants.funderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess())))&&
            {
                pathname: '/configurations',
                state: { page: 'configurations' },
                title: "Configurations",
                name: "configurations",
                icon: "configurations"
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithoutOA === getUserRole()
                || constants.funderWithoutOAandROU === getUserRole() || constants.funderWithOAandBoth === getUserRole()
                ||constants.funderWithoutOAandBoth === getUserRole()
                || constants.nonFunderWithOA === getUserRole()
                || constants.nonFunderWithOAandBoth === getUserRole() || constants.nonFunderWithOAandHO === getUserRole()
                //|| (constants.nonFunderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess())
                //|| (constants.nonFunderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess())
                )
            && {
                pathname: '/archives',
                state: { page: 'archivesList' },
                title: "Archive",
                name: "archives",
                icon: "archives"
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()
                || constants.funderWithOA === getUserRole() || constants.funderWithoutOA === getUserRole() || constants.readOnlyFunderWithoutOA === getUserRole()
                || constants.funderWithOAandHO === getUserRole() || constants.funderWithoutOAandHO === getUserRole()
                || (constants.funderWithOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess()) || (constants.funderWithOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess()) 
                || (constants.funderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && !getHomeownershipAccess())
                || (constants.funderWithoutOAandBoth === getUserRole() && getMultifamilyAccess() && getHomeownershipAccess())
                || constants.funderWithoutOAandROU === getUserRole()
            )
            && {
                pathname: '/mailTemplate',
                state: { page: 'mailTemplateList' },
                title: "Mail Templates",
                name: "mailTemplate",
                icon: "mailTemplate"
            },
            getUserOrgAccess() === 2 ?
                {
                    pathname: '/help/users',
                    state: { page: 'help', type: 10 },
                    title: "Help",
                    name: "help",
                    icon: "help",
                    helpClassName: "help"
                } :
                {
                    pathname: '/help/general',
                    state: { page: 'help', type: 1 },
                    title: "Help",
                    name: "help",
                    icon: "help",
                    helpClassName: "help"
                },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()) && maintenance.maintenance && {
                pathname: '/incomerent',
                state: { page: 'incomerent' },
                title: "Settings",
                name: "incomerent",
                icon: "settings",
                // path:true
            },
            (constants.superFunder === getUserRole() || constants.superFunderWithHO === getUserRole()) && maintenance.maintenance && {
                pathname: '/logs',
                state: { page: 'logs' },
                title: "Logs",
                name: "logs",
                icon: "logs",
                // path:true
            },
        ];
        this.Menus = menuList.filter(elem => elem)
    }
    /**
     * Handle Drawer Open Event
     */
    handleDrawerOpen = (isOpen) => {
        this.setState({ open: isOpen });
    };

    /**
     * A method to navigate when the menu item is clicked
     *@param {*} menu
     * @memberof SideMenu
     */
    navigate = (menu) => {
        history.push(menu)
    }
    /**
     * Get Icons
     * @param {*} icon 
     * @returns 
     */
    getIcons = (icon) => {
        switch (icon) {
            case 'logs':
                return (<LogsIcon />)
            case 'settings':
                return (<SettingsIcon />)
            case 'loanProjects':
                return (<ContractActivity />)
            case 'dashboard':
                return (<DashboardIcon />)
            case 'dashboardho':
                return (<DashboardIconHO />)
            case 'projects':
                return (<ProjectsIcon />)
            case 'sites':
                return (<SitesIcon />)
            case 'buildings':
                return (<BuildingsIcon />)
            case 'units':
                return (<UnitsIcon />)
            case 'organizations':
                return (<OrganizationIcon />)
            case 'users':
                return (<UsersIcon />)
            case 'compliance':
                return (<ComplianceIcon />)
            case 'explore':
                return (<ExploreDataIcon />)
            case 'configurations':
                return (<ConfigurationsIcon />)
            case 'archives':
                return (<ArchiveIcon />)
            case 'mailTemplate':
                return (<MailTemplateIcon />)
            case 'help':
                return (<HelpIcon />)
            case 'occupants':
                return (<OccupantsIcon />)
            case 'inspectionIcon' :
                return (<InspectionIcon />)
            default:
                return (<i className={icon}></i>)
        }
    }
    /**
     * Bind HTML to DOM
     * @returns 
     * 
     * [1]  -   Filter menus for maintenance mode
     * [2]  -   Filter configurations menu
     * [3]  -   Filter Compliane menu
     * [4]  -   Filter Occupants menu
     * [5]  -   Filter Complaince Menu if both settings and list exists
     */
    render() {
        const { classes, location: { pathname }, maintenance } = this.props;
        // [1]
        if (maintenance && maintenance.maintenance) {
            this.Menus = this.Menus.filter(elem => elem.name === 'logs' || elem.name === 'incomerent' || elem.name === 'projects')
        }
        let page = pathname.split('/');
        const userRole = getUserRole();
        const userOrgId = getUserOrgId();
        let MenuItems = this.Menus;
        let configAccessRoles=[constants.superFunder, constants.superFunderWithHO, constants.funderWithOA, constants.funderWithoutOA, constants.funderWithOAandBoth, constants.funderWithOAandHO, constants.funderWithoutOAandHO,constants.funderWithoutOAandBoth, constants.readOnlyFunderWithoutOA, constants.readOnlyFunderWithOA];


        if(userRole===constants.funderWithoutOAandBoth && getMultifamilyAccess() && !getHomeownershipAccess()){
        configAccessRoles.push(constants.funderWithoutOAandBoth)
        }

        if(getUserOrgId()!==2 && userRole===constants.funderWithOAandBoth && getHomeownershipAccess() && !getMultifamilyAccess()){
            configAccessRoles = configAccessRoles.filter(item=>item!==constants.funderWithOAandBoth)
        }


        const complianceAccessRoles = [
            constants.superFunderWithHO,
            constants.superFunder,
            constants.funderWithOA,
            constants.nonFunderWithOA,
            constants.nonFunderWithoutOA,
            constants.funderWithOAandBoth,
            constants.nonFunderWithOAandBoth,
            constants.nonFunderWithoutOAandBoth,
            constants.onsite,
            constants.funder,
        ];

        const occupantsAccessRoles = [
            constants.nonFunderWithOA,
            constants.nonFunderWithoutOA,
            constants.nonFunderWithOAandBoth,
            constants.nonFunderWithoutOAandBoth,
            constants.onsite
        ];
        // [2]
        if (configAccessRoles.indexOf(userRole) < 0) {
            MenuItems = MenuItems.filter(function (item) {
                return item.name !== 'configurations'
            });
        }
        // [3]
        if (complianceAccessRoles.indexOf(userRole) < 0 || (constants.funderWithOA === userRole && userOrgId !== 1 && userOrgId !== 2) 
        || (constants.funderWithOAandBoth === userRole && userOrgId !== 1 && userOrgId !== 2)
        || (constants.nonFunderWithoutOAandBoth === userRole && !getMultifamilyAccess())
        ) {
            MenuItems = MenuItems.filter(function (item) {
                return item.name !== 'compliance'
            });
        }
        // [4]
        if (occupantsAccessRoles.indexOf(userRole) < 0) {
            MenuItems = MenuItems.filter(function (item) {
                return item.name !== 'occupants'
            });
        }
        //[5]
        let complianceSettingIndex = MenuItems.findIndex(x => x.pathname ==="/compliance/setting");
        let complianceIndex = MenuItems.findIndex(x => x.pathname ==="/compliance");
        if(complianceSettingIndex!==-1 && complianceIndex!==-1 ){
            MenuItems.splice(complianceIndex,1)
        }

        if(userRole===constants.nonFunderWithOAandBoth && !getMultifamilyAccess()){
            MenuItems.splice(complianceIndex,1)
        }
        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(`${classes.drawerPaper} sidebarss`, !this.state.open && classes.drawerPaperClose),
                }}
                open={this.state.open}
            >
                <div className={`${classes.toolbar}`} />
                <List component="nav">
                    <ListItem
                        style={{ height: '46px' }}
                        button onClick={() => this.handleDrawerOpen(this.state.open === false)}
                    >
                        <ListItemIcon>
                            <MenuIcon />
                        </ListItemIcon>
                        <ListItemText primary="Menu" />
                    </ListItem>
                    {
                        // render menus here
                        MenuItems.map((menu, index) => {
                            let sethighlight = page[1] === menu.table1 ? 'MuiListItem-active Projects' : page[1] === menu.name && menu.occupan === undefined ? 'MuiListItem-active Projects' : page[2] === menu.name && page[1] === "bulk-upload" && menu.bulkupload === "bulk-upload-building" ? `MuiListItem-active Buildings` : (page[1] === menu.name || page[1] === menu.bulkupload) && page[2] !== "buildings" ? `MuiListItem-active ${menu.title}` : '';
                            return (
                                <ToolTip key={'tt' + index} title={menu.title} placement="right">
                                    <ListItem
                                        style={{ height: '46px' }}
                                        key={'li' + index}
                                        button onClick={() => this.navigate(menu)}
                                        className={sethighlight}>
                                        <ListItemIcon>
                                            {this.getIcons(menu.icon)}
                                        </ListItemIcon>
                                        <ListItemText primary={menu.title} />
                                    </ListItem>
                                </ToolTip>
                            );
                        })
                    }
                </List>
            </Drawer>
        );
    }
}

//default props
SideMenu.defaultProps = {
    classes: {}
}

//prop types
SideMenu.propTypes = {
    classes: PropTypes.object,
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        maintenance: state.maintenance.data
    }
};
//export component
export default connect(
    mapStateToProps,
)(withStyles(sideMenuStyle)(SideMenu));