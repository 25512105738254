import { pageAccessConstants } from '../constants';

const initialState = {
    isLoading: false,
    isPageAccessCompleted: false
}

export function pageAccess(state = { ...initialState }, action) {

    switch (action.type) {
        case pageAccessConstants.GET_USER_PAGE_ACCESS_REQUEST:
            return {
                ...state,
                [action.pageName]: [],
                isLoading: true,
                isPageAccessCompleted: false
            }
        case pageAccessConstants.GET_USER_PAGE_ACCESS_SUCCESS:
            return {
                ...state,
                [action.pageName]: action.access,
                isLoading: false,
                isPageAccessCompleted: true
            };
        case pageAccessConstants.GET_USER_PAGE_ACCESS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
                isPageAccessCompleted: false
            };
        case pageAccessConstants.REMOVE_ACCESS:
            const accessList = state[action.name] || [];
            const updatedAccess = accessList.filter(ac => action.values.indexOf(ac) === -1);
            return {
                ...state,
                [action.name]: updatedAccess
            }
        default: return state;
    }
}