import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Redux Actions and Helper Variable
import { projectsActions } from '../../actions/projects';
import { confActions } from '../../actions/configuration';
import { reportTablesActions } from '../../actions/reportTables';
import { dashboardActions } from '../../actions';

// Import Helpers
import { getUserRole, history, getUserId, getUserOrgId, getUserAccessForPage, globalVariable, filterSearchFields } from '../../helpers';
import { reportLevelConstants } from '../../helpers/appConstants';

// Custom Variables
let sFields = [];
let sValues = [];
/**
 * TableField Component
 * @class TableField
 * @extends {Component}
 */
class TableField extends Component {
    render() {
        const { path, data } = this.props;
        const tableKey = path.split(".")[0];
        let reportLevelIndex = data[tableKey][0] ? data[tableKey][0].levelTo_id : 1;
        const tableData = data[tableKey][0] || {};

        let orgsForthisYear = data.orgFRY.filter(orgD => {
            if (orgD.fry <= data.year &&
                new Date(orgD.expirationDate).getTime() > new Date(`${data.year}-06-30`).getTime()) {
                return true;
            }
            return false;
        });

        orgsForthisYear = orgsForthisYear.map(orgD => orgD.org_id);

        // check if funder org is 1 and only one
        if (orgsForthisYear.length === 1 && orgsForthisYear[0] === 1 && (tableKey.indexOf("table2") > -1 || tableKey.indexOf("table3") > -1)) {
            return <span>{"N/A"}</span>;
        }

        // if site type is not default
        if (data.site.type_id !== 'Default' && (tableKey.indexOf("table1") > -1 || tableKey.indexOf("table5") > -1)) {
            return <span>{"N/A"}</span>;
        }

        // if no on site manager present
        if ((data.site.onsiteManager.user_id === 0 || data.site.onsiteManager.user_id === null) && reportLevelIndex === 1) {
            reportLevelIndex = 2;
        }

        // for table funder waiving
        // let isTableReq = data[`tableReq`]
        let tableIndex = tableKey.match(/\d+/g)[0];
        tableIndex = parseInt(tableIndex, 10);
        let isRequiredTable = data[`table${tableIndex}Req`];
        if (isRequiredTable === false) {
            return <span>{"N/A"}</span>;
        }

        // append date of submission, if exists
        const date = tableData.date ? tableData.date : '';

        return <span>{`${date} ${reportLevelConstants[reportLevelIndex]}`}</span>;
    }
}

TableField.defaultProps = {
    data: {},
    path: ""
}

const componentMap = {
    "table1Activity.levelTo_id": TableField,
    "table2Activity.levelTo_id": TableField,
    "table3Activity.levelTo_id": TableField,
    "table4Activity.levelTo_id": TableField,
    "table5Activity.levelTo_id": TableField
}
/**
 * ReportsList Component
 * @class ReportsList
 * @extends {Component}
 */
class ReportsList extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            fieldFilter: false
        };

        this.userRef = React.createRef();

        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.pageAccess = getUserAccessForPage('projects_reportTab');

        // Clear Tables Values
        this.props.clearTables({});
    }
    /**
     * Component Did Mount Event
     */
    UNSAFE_componentWillMount() {
        const { page } = this.props.reports;
        const projectId = this.props.projectId;
        let query = {}
        if (this.props.page === 'archives') {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        // retain old search values
        if (page.skip) {
            sFields = page.sFields;
            sValues = page.sValues;
            this.props.getProjectReport(page.skip, globalVariable.subPagetableRowSize, page.orderBy, page.order, page.sFields, page.sValues, projectId, query);
        } else {
            sFields = [];
            sValues = [];
            this.props.getProjectReport(1, globalVariable.subPagetableRowSize, 'year', 'desc', '', '', projectId, query);
        }
        console.log("== Reports Data ==", this.props.reports)
        if (projectId) {
            this.props.getByProjectId(projectId)
        }
    }
    /**
    * Component Did Update
    */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { reports } = this.props;
        let query = {}
        if (this.props.page === 'archives') {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } const projectId = this.props.projectId || this.props.copyId;
        this.props.getProjectReport(skip, limit, reports.page.orderBy, reports.page.order, sFields, sValues, projectId, query);
    };
    /**
     * Handle Tasites
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { reports } = this.props;
        let query = {}
        if (this.props.page === 'archives') {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } const projectId = this.props.projectId || this.props.copyId;
        this.props.getProjectReport(reports.page.skip, reports.page.limit, orderBy, order, sFields, sValues, projectId, query);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { reports } = this.props;
        const projectId = this.props.projectId || this.props.copyId;

        let query = {}
        if (this.props.page === 'archives') {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.getProjectReport(1, globalVariable.subPagetableRowSize, reports.page.orderBy, reports.page.order, sFields, sValues, projectId, query);
    };
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const projectId = this.props.projectId || this.props.copyId;
        sFields = [];
        sValues = [];
        const { reports } = this.props;
        let query = {}
        if (this.props.page === 'archives') {
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        } this.props.getProjectReport(reports.page.skip, reports.page.limit, reports.page.orderBy, reports.page.order, sFields, sValues, projectId, query);
    };
    /**
    * Handle Field Filter Action Event
    */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        // this.props.changeFieldFilter("projectreports", this.props.funders.page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("projectReport", this.props.reports.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("projectReport", updatedColumns);
        } else {
            this.props.changeFieldFilter("projectReport", [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        const projectId = this.props.projectId || this.props.copyId;
        this.props.updateFieldFilter("projectReport", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { reports } = this.props;
            let query = {}
            if (this.props.page === 'archives') {
                query = {
                    isDelete: true
                }
            } else {
                query = {
                    isDelete: false
                }
            } this.props.getProjectReport(reports.page.skip, reports.page.limit, reports.page.orderBy, reports.page.order, sFields, sValues, projectId, query);
        });
    }
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {

        // Table 1 Validation for table 5 report (front end handling)

        // let { reports } = this.props;
        // let reportData = reports && reports?.data;
        // let tableData = reportData.filter((x) => x.year == item.year && x.site_id == item.site_id);
        // let table5Req = tableData.length > 0 ? tableData[0].table5Req : false;
        // let table5Level = tableData.length > 0 ? tableData[0].table5Level : 0;
        // let table1Level = tableData.length > 0 ? tableData[0].table1Level : 0;
        switch (field) {
            case 'year':
                const { match, projectId } = this.props;
                history.push({
                    pathname: `${match.url}/${id}/${item.year}`,
                    state: {
                        page: 'reportView',
                        site_id: item.site_id,
                        pageData: {
                            project_id: parseInt(projectId, 10),
                            reportYear: item.year,
                            site_id: item.site_id,
                            page: this.props.page,
                            // table5Level,
                            // table1Level,
                            // table5Req,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            case 'site.name':
                history.push({
                    pathname: `/sites/${item.site_id}`,
                    state: {
                        page: 'siteEdit',
                        pageData: {
                            site_id: item.site_id,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            default: break;
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { columnsMap, visibleColumns, reports, location: { pathname },
            needPadding, isSubPage, title, classes } = this.props;
        const { fieldFilter } = this.state;
        const { userRole, userOrgId, pageAccess } = this;
        const fieldFilters = reports.page ? reports.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                needPadding={needPadding}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
            >
                <TableComponent
                    page={reports.page.skip}
                    rowsPerPage={reports.page.limit}
                    count={reports.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    componentMap={componentMap}
                    actions={pageAccess}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    links={[fieldFilters.fieldCols[1]]}
                    order={reports.page.order}
                    orderBy={reports.page.orderBy}
                    data={reports.data}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    paginationVisible={true}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    handleDownloadAction={(item) => {
                        this.props.viewandDownloadProjectSummaryReport(item);
                    }}
                    noDataErrorMessage={reports.isLoading ? "" : "No Records Found"}
                />

                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
ReportsList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};
/** Default Props */
ReportsList.defaultProps = {
    projectId: null,
    copyId: null,
    needPadding: false,
    isSubPage: false,
    page: 'reports',
    title: 'All Reports'
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['projectReport'] || {};
    return {
        reports: state.projects.getOne.reports,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getProjectReport: (skip, limit, orderBy, order, sField, sValue, projectId, query) => dispatch(projectsActions.getProjectReport(skip, limit, orderBy, order, sField, sValue, projectId, query)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getByProjectId: (projectId) => dispatch(projectsActions.getById(projectId)),
        viewandDownloadProjectSummaryReport: (data) => dispatch(dashboardActions.viewandDownloadProjectSummaryReport(data)),
        clearTables: () => dispatch(reportTablesActions.clearTables()),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ReportsList));
